<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{ icon }}</v-icon>
      {{ title }}
    </v-card-title>
    <v-spacer></v-spacer>
      <v-form>
        <v-row align="center" no-gutters>
          <v-col
            class="px-5 py-1"
            :class="data.class"
            v-for="(data, index) in formData"
            :key="index"
          >
            <component v-bind:is="data.fieldName" :item="data"></component>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-cancel" 
          variant="plain" @click.native="cancel" size="large">
          Cancel
        </v-btn>
        <v-divider vertical class="mx-6"></v-divider>
        <v-btn
          prepend-icon="mdi-content-save" 
          variant="outlined"
          @click.native="submit"
          size="large"
          :loading="loading || fileLoading"
        >
          Save
        </v-btn>
      </v-card-actions>
  </v-card>
</template>
<script setup>
  import { storeToRefs } from 'pinia'
  import { useRouter,useRoute } from 'vue-router'
  import {  useForm } from 'vee-validate';
  import * as yup from 'yup';
  const router = useRouter();
  const route = useRoute();
  const props = defineProps({
  store: Object,
  formData: Array,
  title: String,
  icon: String,
  namespace: String
  })

const makeSchema = (formData) => {
  let schema = {}
  formData.forEach((item) => {
    schema[item.name] = item.rules
  })
  return yup.object(schema)
}
const makeValues = (formData) => {
  let initialValues = {}
  formData.forEach((item) => {
    initialValues[item.name] = item.value
  })
  return initialValues
}
const validationSchema = makeSchema(props.formData)
const initialValues = makeValues(props.formData)
const { handleSubmit } = useForm({
      validationSchema,
      initialValues: initialValues,
    }
    );
  const {  loading, fileLoading } = storeToRefs(props.store)
  
const cancel = async () => {
   router.go(-1);
  }
  const { insertItem, updateItem } = props.store
  
  const submit = handleSubmit(async (values) => {
    if(route.params.id){
      await updateItem({id: route.params.id, values: values})
    }
    else{
    await insertItem(values)
    }
   
   router.go(-1);
  })
  
</script>

