import { ref } from 'vue';
import { useFileStore } from '@/stores/file'

export default function useFileUploader() {
  const file = ref(null);
  const fileName = ref('');
  const selected = ref(false);
  const preview = ref(null);
  const fileStore = useFileStore();
  const { uploadFilePublic } = fileStore;

    async function handleFileChange(event,item) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        selected.value = true;
      const uploadData = await uploadFilePublic({file:selectedFile,filePath:item.filePath})
      preview.value = uploadData.preview_url;
      file.value = uploadData.file_name;
      fileName.value = selectedFile.name;
    } else {
      file.value = null;
      fileName.value = '';
      selected.value = false;
    }
  }

  return {
    file,
    fileName,
    preview,
    handleFileChange
  };
}
