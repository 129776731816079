<template>
          <v-text-field
            v-model="value"
            clearable
            :placeholder="item.placeholder"
            :label="item.text"
            :error-messages="errorMessage"
            prepend-inner-icon="mdi-calendar"
            variant="outlined"
            v-bind="props"
            type="date"
          ></v-text-field>
</template>
<script setup>
import { useField } from 'vee-validate';
const props = defineProps({
item: Object
})
const {
  value,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.item.name,props.item.rules);


</script>
