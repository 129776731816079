<template>
        <v-row no-gutters class="align-center justify-center">
          <v-col cols="6" sm="6" md="6" >
            <v-btn variant="plain" color=" primary" size="large" @click="generatePassword"
              >Generate Password
              <v-icon small class="ml-2">mdi-arrow_forward</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" sm="6" md="6" >
            <v-text-field
                autocomplete="new-password"
                v-model="value"
                :placeholder="item.placeholder"
                :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :label="item.text"
                :name="item.text"
                clearable
                :type="showPassword ? 'text' : 'password'"
                :error-messages="errorMessage"
                variant="outlined"
                :hint="item.hint"
                persistent-hint
                :hide-details="item.hint?false:true"
                @click:append-inner="showPassword = !showPassword"
                @input="handleChange"
                @blur="handleBlur">
            </v-text-field>
          </v-col>
        </v-row>
  </template>
  <script setup>
  import { useField } from 'vee-validate';
  import {ref} from 'vue';
  const showPassword = ref(false);
  const props = defineProps({
  item: Object
  })
  const generatePassword = () => {
        let newPassword = Math.random()
          .toString(36)
          .slice(-8);
        value.value = newPassword
        showPassword.value = true;
      }
  const {
    value,
    errorMessage,
    handleBlur,
    handleChange
  } = useField(props.item.name,props.item.rules);
  </script>
  