<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useAccordStore } from '@/stores/accord'
import { useProductStore } from '@/stores/product'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useAccordStore()
const productStore = useProductStore()
const formData = [
        {
          text: "Name*",
          value: "",
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-12",
          rules:  yup.string().required()
        },
        {
          text: "Description",
          placeholder: "Description",
          fieldName: "StandardTextAreaField",
          value: "",
          name: "description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Image small*",
          value: [],
          placeholder: "Image small",
          name: "image",
          filePath: 'accords/small/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Image large*",
          value: [],
          filePath: 'accords/large/',
          placeholder: "Image large",
          name: "image_large",
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Products and Accords",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          name:"products",
          text: "Product SKU and Accord value",
          value:[{product_id: null, value: null}],
          model1: "product_id",
          model2: "value",
          store: productStore,
          placeholder: "Product SKU and Accord value",
          fieldName: "SelectAndInputField",
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        }
      ];

</script>
 
