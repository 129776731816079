import View from "@/views/admin/Product.vue";
import Index from "@/components/admin/products/List.vue";
import Create from "@/components/admin/products/Create.vue";
import Edit from "@/components/admin/products/Edit.vue";
export default [
  {
    path: "/products",
    name: "products",
    component: View,
    title: "Products",
    redirect: {
      name: "list-product"
    },
    children: [
      {
        path: "list",
        name: "list-product",
        meta: {
          icon: "mdi-cart"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-product",
        meta: {
          storeAction: "product/insertItem",
          namespace: "product",
          store: "product",
          title: "Create product",
          icon: "mdi-cart"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-product",
        meta: {
          storeAction: "product/updateItem",
          namespace: "product",
          store: "product",
          title: "Edit product",
          icon: "mdi-cart"
        },
        component: Edit
      }
    ]
  }
];
