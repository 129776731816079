import axios from "axios";

let config = {
	baseURL: import.meta.env.VITE_APP_API_URL || "/api"
};
const _axios = axios.create(config);
export default class RestApi {
	constructor() {
		this.domain = import.meta.env.VITE_APP_API_URL;
		this.$axios = _axios;
	}

	authHeader() {
		let token = localStorage.getItem('token');

		if (token) {
			return { Authorization: 'Bearer ' + token };
		} else {
			return {};
		}
	}

	async getData(url, pagination = null) {
		try {
			let data = {};
			if (pagination != null) data = pagination;
			const response = await this.$axios.get(url, {
				params: data,
				headers: this.authHeader()
			});
			return response.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Failed to get data');
		}
	}

	async postData(url, data) {
		try {
			const response = await this.$axios.post(url, data, { headers: this.authHeader() });
			return response.data;
		} catch (error) {
			console.log(error.message)
			console.log(error)
			throw new Error(error?.response?.data?.message || 'Failed to submit data');
		}

	}

	async putData(url, data) {
		try {
			const response = await this.$axios.put(url, data, { headers: this.authHeader() });
			return response.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Failed to submit data');
		}
	}

	async deleteData(url) {
		try {
			let response = await this.$axios.delete(url, { headers: this.authHeader() });
			return response.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Failed to delete data');
		}
	}
}
