import View from "@/views/admin/Order.vue";
import Index from "@/components/admin/orders/List.vue";
export default [
  {
    path: "/orders",
    name: "orders",
    component: View,
    title: "Orders",
    redirect: {
      name: "list-order"
    },
    children: [
      {
        path: "list",
        name: "list-order",
        meta: {
          icon: "mdi-format-list-bulleted"
        },
        component: Index
      },
      
    ]
  }
];
