<template>
      <v-autocomplete
        class="no-drag pa-0"
        autocomplete="off"
        :items="item.items"
        :item-value="item.itemValue || 'value'"
        :item-title="item.itemText || 'text'"
        :multiple="item.multiple ? item.multiple : false"
        chips
        v-model="value"
        :placeholder="item.placeholder"
        :label="item.text"
        clearable
        :error-messages="errorMessage"
        variant="outlined"
      >
      </v-autocomplete>
</template>
<script setup>
import { useField } from 'vee-validate';
const props = defineProps({
item: Object
})
const {
  value,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.item.name,props.item.rules);
</script>

