import { defineStore } from "pinia";

interface Snackbar {
  message: string;
  color: string;
  active: boolean;
}

export const useSnackbarStore = defineStore({
  id: "snackbar",
  state: () => ({
    snackbar: null as Snackbar | null,
  }),
  actions: {
    success(message: string) {
      this.snackbar = { message, color: "success", active: true };
    },
    error(message: string) {
      this.snackbar = { message, color: "error", active: true };
    },
    info(message: string) {
      this.snackbar = { message, color: "blue-grey", active: true };
    },
    clear() {
      this.snackbar = null;
    },
  },
});
