import { useUserStore } from '@/stores/user';
export default function guest ({ next }){
    const userStore = useUserStore()
    if(userStore.token){
        return next({
           name: 'dashboard'
        })
    }
   
    return next()
   }