<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useBottleStore } from '@/stores/bottle'

const namespace = 'bottle'
const icon = 'mdi-bottle-wine'
const title = 'Bottles'
const store = useBottleStore()
</script>
