<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useShopuserStore } from '@/stores/shopuser'

const namespace = 'shopuser'
const icon = 'mdi-account-check'
const title = 'Shop users'
const store = useShopuserStore()
</script>
