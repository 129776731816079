<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useRawdataStore } from '@/stores/rawdata'
import { useAccordStore } from '@/stores/accord'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useRawdataStore()
const accordStore = useAccordStore()
const formData = [
        {
          text: "Name*",
          value: "",
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Brand",
          placeholder: "Brand",
          fieldName: "StandardTextField",
          value: "",
          name: "brand",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Image*",
          value: [],
          placeholder: "Image",
          multiple:true,
          name: "image",
          filePath: 'rawdata/images/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Thumbnail*",
          value: [],
          multiple:true,
          placeholder: "Thumbnail",
          name: "thumbnail",
          filePath: 'rawdata/thumbnails/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Gender",
          placeholder: "Gender",
          fieldName: "StandardTextField",
          value: "",
          name: "gender",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Year",
          placeholder: "Year",
          fieldName: "StandardTextField",
          value: "",
          name: "year",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Perfume Rating",
          placeholder: "Perfume Rating",
          fieldName: "StandardTextField",
          value: "",
          name: "perfume_rating",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Perfume Rating Count",
          placeholder: "Perfume Rating Count",
          fieldName: "StandardTextField",
          value: "",
          name: "perfume_rating_count",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Short Description",
          placeholder: "Short Description",
          fieldName: "StandardTextAreaField",
          value: "",
          name: "short_description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Long Description",
          placeholder: "Long Description",
          fieldName: "StandardTextAreaField",
          value: "",
          name: "long_description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Top Notes",
          placeholder: "Top Notes",
          fieldName: "StandardTextField",
          value: "",
          name: "top_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Middle Notes",
          placeholder: "Middle Notes",
          fieldName: "StandardTextField",
          value: "",
          name: "middle_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Base Notes",
          placeholder: "Base Notes",
          fieldName: "StandardTextField",
          value: "",
          name: "base_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Fragrance Notes",
          placeholder: "Fragrance Notes",
          fieldName: "StandardTextField",
          value: "",
          name: "fragrance_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Value for money votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Way overpriced",
          placeholder: "Way overpriced",
          fieldName: "StandardTextField",
          value: "",
          name: "way_overpriced",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Overpriced",
          placeholder: "Overpriced",
          fieldName: "StandardTextField",
          value: "",
          name: "overpriced",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Good value",
          placeholder: "Good value",
          fieldName: "StandardTextField",
          value: "",
          name: "good_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Great value",
          placeholder: "Great value",
          fieldName: "StandardTextField",
          value: "",
          name: "great_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "OK value",
          placeholder: "OK value",
          fieldName: "StandardTextField",
          value: "",
          name: "ok_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Longevity votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Very weak",
          placeholder: "Very weak",
          fieldName: "StandardTextField",
          value: "",
          name: "very_weak",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Weak",
          placeholder: "Weak",
          fieldName: "StandardTextField",
          value: "",
          name: "weak",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Moderate",
          placeholder: "Moderate",
          fieldName: "StandardTextField",
          value: "",
          name: "moderate",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Long lasting",
          placeholder: "Long lasting",
          fieldName: "StandardTextField",
          value: "",
          name: "long_lasting",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Eternal",
          placeholder: "Eternal",
          fieldName: "StandardTextField",
          value: "",
          name: "eternal",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Love votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Love",
          placeholder: "Love",
          fieldName: "StandardTextField",
          value: "",
          name: "love",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Like",
          placeholder: "Like",
          fieldName: "StandardTextField",
          value: "",
          name: "like",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "OK",
          placeholder: "OK",
          fieldName: "StandardTextField",
          value: "",
          name: "ok",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Dislike",
          placeholder: "Dislike",
          fieldName: "StandardTextField",
          value: "",
          name: "dislike",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Hate",
          placeholder: "Hate",
          fieldName: "StandardTextField",
          value: "",
          name: "hate",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },

        {
          text: "Weather votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Winter",
          placeholder: "Winter",
          fieldName: "StandardTextField",
          value: "",
          name: "winter",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Spring",
          placeholder: "Spring",
          fieldName: "StandardTextField",
          value: "",
          name: "spring",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },       
        {
          text: "Summer",
          placeholder: "Summer",
          fieldName: "StandardTextField",
          value: "",
          name: "summer",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        }, 
        {
          text: "Fall",
          placeholder: "Fall",
          fieldName: "StandardTextField",
          value: "",
          name: "fall",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Day",
          placeholder: "Day",
          fieldName: "StandardTextField",
          value: "",
          name: "day",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Night",
          placeholder: "Night",
          fieldName: "StandardTextField",
          value: "",
          name: "night",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Rawdata and Accords",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          name:"accords",
          text: "Accord name and Accord value",
          value:[{accord_id: null, value: null}],
          model1: "accord_id",
          model2: "value",
          store: accordStore,
          placeholder: "Accord name and Accord value",
          fieldName: "SelectAndInputField",
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        }
      ];

</script>
 
