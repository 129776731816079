<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useProductStore } from '@/stores/product'
import { useAccordStore } from '@/stores/accord'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useProductStore()
const accordStore = useAccordStore()
const formData = [
        {
          text: "SKU*",
          placeholder: "SKU*",
          fieldName: "StandardTextField",
          value: "",
          name: "sku",
          class: "v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Name*",
          value: "",
          placeholder: "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Description",
          value: "",
          placeholder: "Description",
          name: "description",
          fieldName: "StandardTextAreaField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-12",
          rules:  ""
        },       
        {
          text: "Is it popular?:",
          placeholder: "Is it popular?",
          fieldName: "SwitchField",
          value: 0,
          trueValue: 1,
          falseValue:0,
          name: "is_popular",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          hint:"If you select this option, the product will be displayed on the profile selection page",
          rules:  ""
        },
        {
          text: "Select gender:",
          placeholder: "Product Gender:",
          fieldName: "SelectField",
          value: 'unisex',
          name: "gender",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          multiple: false,
          chips: true,
          items: ["men", "women","unisex"],
          rules:  yup.string()
        },
        {
          text: "Price ($)*",
          value: "",
          placeholder: "Price ($)*",
          name: "price",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-12",
          rules:  yup.string().required()
        },
        {
          text: "Image",
          value: [],
          placeholder: "Image",
          name: "image",
          filePath: 'products/',
          fileNames: [],
          multiple:true,
          fieldName: "FileUploadSimpleField",
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },
        {
          text: "Products and Accords",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          name:"accords",
          text: "Accord name and Accord value",
          value:[{accord_id: null, value: null}],
          model1: "accord_id",
          model2: "value",
          store: accordStore,
          placeholder: "Accord name and Accord value",
          fieldName: "SelectAndInputField",
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
       
      ];

</script>
 
