import RestApi from "@/api/RestApi";

export default class UsersApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "auth/";
    this.userBaseEndpoint = this.domain + "users/";
  }

  async login(data) {
    let url = this.baseEndpoint + 'login';
    let response = await this.postData(url, data);
    return response;
  }

  async getList(pagination) {
    let url = this.userBaseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getItem(id) {
    let url = this.userBaseEndpoint + id;
    let response = await this.getData(url);
    return response;
  }

  async insertItem(item) {
    let url = this.userBaseEndpoint;
    let response = await this.postData(url, item);
    return response;
  }

  async updateItem(id, item) {
    let url = this.userBaseEndpoint + id;
    let response = await this.putData(url, item);
    return response;
  }

  async deleteItem(id) {
    let url = this.userBaseEndpoint + id;
    let response = await this.deleteData(url);
    return response;
  }
}
