<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useAccordStore } from '@/stores/accord'

const namespace = 'accord'
const icon = 'mdi-flower'
const title = 'Accords'
const store = useAccordStore()
</script>
