import View from "@/views/admin/Usermix.vue";
import Index from "@/components/admin/usermixes/List.vue";
export default [
  {
    path: "/usermixes",
    name: "usermixes",
    component: View,
    title: "Usermixes",
    redirect: {
      name: "list-usermix"
    },
    children: [
      {
        path: "list",
        name: "list-usermix",
        meta: {
          icon: "mdi-heart"
        },
        component: Index
      },
      
    ]
  }
];
