<template>
  <v-dialog  v-model="localDialog" persistent max-width="310">
    <v-card>
      <v-card-title class="error pl-3 white--text">Confirm Delete</v-card-title>
      <v-card-text class="body-1 pa-3">Are you sure you want to delete this item?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click.native="$emit('cancel-delete')">
          <v-icon size="20" left>mdi-cancel</v-icon>
          Cancel
        </v-btn>
        <v-btn color="error" outlined @click="$emit('delete')">
          <v-icon size="20" left>mdi-trash-can-outline</v-icon>Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { computed } from "vue";
  const props = defineProps({
  dialog: Boolean,
  })
  const localDialog = computed(() => { return props.dialog })

  </script>