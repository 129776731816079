<template>
  <v-row class=" align-center justify-center">
  <v-col
        cols="4"
        v-if="preview"
      >
              <v-card flat class="text-center align-center" height="100px" cover>
               <v-img :src="preview"></v-img>
              </v-card>
      </v-col>
      <v-col :cols="preview?8:12">
          <v-file-input
            v-model="value"
            counter
            ref="uploadFile"
            :placeholder="item.text"
            :error-messages="errorMessage"
            :label="item.text"
            outlined
            variant="outlined"
            :loading="loading"	
            @change="handleFileChange($event,item)"
            :disabled="selected"
            :multiple="item.multiple"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            color="green darken-3"
            show-size
            truncate-length="50"
          ></v-file-input>
        </v-col>
      </v-row>
</template>
<script setup>
import {onMounted } from 'vue'
import { useField } from 'vee-validate';
import { storeToRefs } from 'pinia'
import useFileUpload from '@/composables/useFileUpload.js'
const props = defineProps({
item: Object
})

const { loading } = storeToRefs(props.store)
const { selected, handleFileChange , preview} = useFileUpload()
onMounted(() => {
preview.value = props.item.imagePath
})
const {
  value,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.item.name,props.item.rules);
</script>
