<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useRawdataStore } from '@/stores/rawdata'
import { useAccordStore } from '@/stores/accord'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
import { th } from "vuetify/locale";
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useRawdataStore()
const accordStore = useAccordStore()
const {getItem} = store
await getItem(router.params.id)
const { item } = store

const { name, brand, image,thumbnail,perfume_rating,perfume_rating_count,gender,year,short_description,long_description,
  top_notes,middle_notes,base_notes,fragrance_notes,way_overpriced ,overpriced,good_value,great_value,ok_value,very_weak,weak,moderate,long_lasting,eternal,love,like,ok,dislike,hate,winter,spring,summer,fall,day,night,accords } = item;
const imageName = image!==''? [new File([], image.split(/(\\|\/)/g).pop())] : []
const thumbName = thumbnail!==''? [new File([], thumbnail.split(/(\\|\/)/g).pop())] : []
const formData = [
          {
          text: "Name*",
          value: name,
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Brand",
          placeholder: "Brand",
          fieldName: "StandardTextField",
          value: brand,
          name: "brand",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Image*",
          value: imageName,
          multiple:true,
          imagePath: image,
          placeholder: "Image",
          name: "image",
          filePath: 'rawdata/images/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Thumbnail*",
          value: thumbName,
          multiple:true,
          imagePath: thumbnail,
          placeholder: "Thumbnail",
          name: "thumbnail",
          filePath: 'rawdata/thumbnails/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },
        {
          text: "Gender",
          placeholder: "Gender",
          fieldName: "StandardTextField",
          value: gender,
          name: "gender",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Year",
          placeholder: "Year",
          fieldName: "StandardTextField",
          value: year,
          name: "year",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Perfume Rating",
          placeholder: "Perfume Rating",
          fieldName: "StandardTextField",
          value:perfume_rating ,
          name: "perfume_rating",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Perfume Rating Count",
          placeholder: "Perfume Rating Count",
          fieldName: "StandardTextField",
          value: perfume_rating_count,
          name: "perfume_rating_count",
          class: "v-col-sm-12 v-col-md-3",
          rules: ""
        },
        {
          text: "Short Description",
          placeholder: "Short Description",
          fieldName: "StandardTextAreaField",
          value: short_description,
          name: "short_description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Long Description",
          placeholder: "Long Description",
          fieldName: "StandardTextAreaField",
          value: long_description,
          name: "long_description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Top Notes",
          placeholder: "Top Notes",
          fieldName: "StandardTextField",
          value: top_notes,
          name: "top_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Middle Notes",
          placeholder: "Middle Notes",
          fieldName: "StandardTextField",
          value: middle_notes,
          name: "middle_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Base Notes",
          placeholder: "Base Notes",
          fieldName: "StandardTextField",
          value: base_notes,
          name: "base_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Fragrance Notes",
          placeholder: "Fragrance Notes",
          fieldName: "StandardTextField",
          value: fragrance_notes,
          name: "fragrance_notes",
          class: "v-col-sm-12 v-col-md-6",
          rules: ""
        },
        {
          text: "Value for money votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Way overpriced",
          placeholder: "Way overpriced",
          fieldName: "StandardTextField",
          value: way_overpriced,
          name: "way_overpriced",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Overpriced",
          placeholder: "Overpriced",
          fieldName: "StandardTextField",
          value: overpriced,
          name: "overpriced",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Good value",
          placeholder: "Good value",
          fieldName: "StandardTextField",
          value: good_value,
          name: "good_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Great value",
          placeholder: "Great value",
          fieldName: "StandardTextField",
          value: great_value,
          name: "great_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "OK value",
          placeholder: "OK value",
          fieldName: "StandardTextField",
          value: ok_value,
          name: "ok_value",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Longevity votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Very weak",
          placeholder: "Very weak",
          fieldName: "StandardTextField",
          value: very_weak,
          name: "very_weak",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Weak",
          placeholder: "Weak",
          fieldName: "StandardTextField",
          value: weak,
          name: "weak",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Moderate",
          placeholder: "Moderate",
          fieldName: "StandardTextField",
          value: moderate,
          name: "moderate",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Long lasting",
          placeholder: "Long lasting",
          fieldName: "StandardTextField",
          value: long_lasting,
          name: "long_lasting",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Eternal",
          placeholder: "Eternal",
          fieldName: "StandardTextField",
          value: eternal,
          name: "eternal",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Love votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Love",
          placeholder: "Love",
          fieldName: "StandardTextField",
          value: love,
          name: "love",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Like",
          placeholder: "Like",
          fieldName: "StandardTextField",
          value: like,
          name: "like",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "OK",
          placeholder: "OK",
          fieldName: "StandardTextField",
          value: ok,
          name: "ok",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Dislike",
          placeholder: "Dislike",
          fieldName: "StandardTextField",
          value:dislike,
          name: "dislike",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Hate",
          placeholder: "Hate",
          fieldName: "StandardTextField",
          value:hate,
          name: "hate",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Weather votes",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          text: "Winter",
          placeholder: "Winter",
          fieldName: "StandardTextField",
          value: winter,
          name: "winter",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Spring",
          placeholder: "Spring",
          fieldName: "StandardTextField",
          value: spring,
          name: "spring",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },       
        {
          text: "Summer",
          placeholder: "Summer",
          fieldName: "StandardTextField",
          value: summer,
          name: "summer",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        }, 
        {
          text: "Fall",
          placeholder: "Fall",
          fieldName: "StandardTextField",
          value: fall,
          name: "fall",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Day",
          placeholder: "Day",
          fieldName: "StandardTextField",
          value: day,
          name: "day",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Night",
          placeholder: "Night",
          fieldName: "StandardTextField",
          value: night,
          name: "night",
          class: "v-col-sm-12 v-col-md-2",
          rules: ""
        },
        {
          text: "Rawdata and Accords",
          fieldName: "DividerField",
          color:"indigo",
          class: "v-col-sm-12 v-col-md-12",
        },
        {
          name:"accords",
          text: "Accord name and Accord value",
          value:accords,
          model1: "accord_id",
          model2: "value",
          store: accordStore,
          placeholder: "Accord name and Accord value",
          fieldName: "SelectAndInputField",
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        }
      ];

</script>