import RestApi from "@/api/RestApi";
export default class FilesApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "files/";
	}

	async uploadFilePublic(item) {
		let formData = new FormData();
		formData.append("file", item.file);
		formData.append("file_path", item.filePath);
		let url = this.baseEndpoint + "upload/public";
		const response = await this.postData(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
		return response;
	}
}
