import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import ProductsApi from "@/api/products";
import { useOrderStore } from "@/stores/order";
const restApi = new ProductsApi();
export const useProductStore = defineStore({
  id: "product",
  state: () => ({
    items: [],
    allItems: [],
    total: 0,
    popular: [],
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "SKU", key: "sku", align: "center" },
      { title: "Name", key: "name", align: "center" },
      { title: "Description", key: "description", align: "center" },
      { title: "Image", key: "image", align: "center" },
      { title: "Price ($)", key: "price", align: "center" },
      { title: "Popular", key: "is_popular", align: "center" },
      { title: "Gender", key: "gender", align: "center" },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "name",
      searchItems: ["name", "sku", "id"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getAll() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getAll();
        this.allItems = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async getPopularProfiles() {
      this.loading = true;
      const { currentOrderDetails } = useOrderStore();
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getPopularProfiles(
          currentOrderDetails.gender
        );
        this.popular = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: any) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const cloneItem = structuredClone(item);
        cloneItem.image = item.image[0].name;
        await restApi.insertItem(cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        const cloneItem = structuredClone(values);
        cloneItem.image = cloneItem.image[0].name;
        await restApi.updateItem(item.id, cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
