import '@/styles/main.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import "@mdi/font/css/materialdesignicons.css";

const light = {
	colors: {
		primary: "#0000008c",
		secondary: "#ff9800",
		accent: "#fcfcfc",
		dirtyWhite: "#fefefe",
		error: "#f44336",
		warning: "#ff5722",
		info: "#03a9f4",
		success: "#017d40"
	},
}
const dark = {
	colors: {
		primary: "#0000008c",
		secondary: "#ff9800",
		dirtyWhite: "#fefefe",
		accent: "#fafafa",
		error: "#f44336",
		warning: "#ff5722",
		info: "#03a9f4",
		success: "#017d40"
	},
}

export default createVuetify({
	components,
	directives,
	theme: {
		defaultTheme: "light",
		themes: {
			light,
			dark
		},

	},
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
});
