<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useShopuserStore } from '@/stores/shopuser'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useShopuserStore()
const {getItem} = store
await getItem(router.params.id)
const { item } = store
const { name, contact, country, state, city, address,postalcode,email } = item;
const formData = [
        {
          text: "Name*",
          value: name,
          placeholder: "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Email*",
          value: email,
          placeholder: "Email*",
          name: "email",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required().email()
        },
        {
          text: "Contact",
          value: contact,
          placeholder: "Contact",
          name: "contact",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules: ""
        },
        {
          text: "Country*",
          value: country,
          placeholder: "Country*",
          name: "country",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "State",
          value: state,
          placeholder: "State",
          name: "state",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules: ""
        },
        {
          text: "City*",
          value: city,
          placeholder: "City*",
          name: "city",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Address*",
          value: address,
          placeholder: "Address*",
          name: "address",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Postal code*",
          value: postalcode,
          placeholder: "Postal code*",
          name: "postalcode",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
      ];


</script>