import View from "@/views/admin/Accord.vue";
import Index from "@/components/admin/accords/List.vue";
import Create from "@/components/admin/accords/Create.vue";
import Edit from "@/components/admin/accords/Edit.vue";
export default [
  {
    path: "/accords",
    name: "accords",
    component: View,
    title: "Accords",
    redirect: {
      name: "list-accord"
    },
    children: [
      {
        path: "list",
        name: "list-accord",
        meta: {
          icon: "mdi-flower"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-accord",
        meta: {
          storeAction: "accord/insertItem",
          namespace: "accord",
          store: "accord",
          title: "Create accord",
          icon: "mdi-flower"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-accord",
        meta: {
          storeAction: "accord/updateItem",
          namespace: "accord",
          store: "accord",
          title: "Edit accord",
          icon: "mdi-flower"
        },
        component: Edit
      }
    ]
  }
];
