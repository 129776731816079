import View from "@/views/admin/User.vue";
import Index from "@/components/admin/users/List.vue";
import Create from "@/components/admin/users/Create.vue";
import Edit from "@/components/admin/users/Edit.vue";
export default [
  {
    path: "/users",
    name: "users",
    component: View,
    title: "Admin Users",
    redirect: {
      name: "list-user"
    },
    children: [
      {
        path: "list",
        name: "list-user",
        meta: {
          icon: "mdi-account-star"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-user",
        meta: {
          storeAction: "user/insertItem",
          namespace: "user",
          store: "user",
          title: "Create admin user",
          icon: "mdi-account-star"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-user",
        meta: {
          storeAction: "user/updateItem",
          namespace: "user",
          store: "user",
          title: "Edit admin user",
          icon: "mdi-account-star"
        },
        component: Edit
      }
    ]
  }
];