<template>
    <v-dialog  v-model="localDialog" persistent  max-width="310">
      <v-card>
        <v-card-title class="error pl-3 white--text">User details</v-card-title>
        <v-divider></v-divider>
        <v-list>
            <v-list-item>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Contact</v-list-item-title>
                    <v-list-item-subtitle>{{item.contact}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Country</v-list-item-title>
                    <v-list-item-subtitle>{{item.country}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>State</v-list-item-title>
                    <v-list-item-subtitle>{{item.state}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>City</v-list-item-title>
                    <v-list-item-subtitle>{{item.city}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Address</v-list-item-title>
                    <v-list-item-subtitle>{{item.address}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Postal Code</v-list-item-title>
                    <v-list-item-subtitle>{{item.postalcode}}</v-list-item-subtitle>
                    </v-list-item>

        </v-list>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click.native="$emit('close-user-dialog')">
          <v-icon size="20" left>mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script setup>
  import { computed } from "vue";
  import { useShopuserStore } from '@/stores/shopuser'
  import {storeToRefs} from 'pinia'
  const store = useShopuserStore()
  const {getItem} = store
  const { item } = storeToRefs(store)
    const props = defineProps({
    dialog: Boolean,
    userId: [String, Number],
    })
    await getItem(props.userId)
  const localDialog = computed(() => { return props.dialog })
  
    </script>