<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
    :action-routes="false"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useOrderStore } from '@/stores/order'

const namespace = 'order'
const icon = 'mdi-format-list-bulleted'
const title = 'Orders'
const store = useOrderStore()
</script>
