import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import UsermixApi from "@/api/usermixes";
const restApi = new UsermixApi();
export const useUsermixStore = defineStore({
  id: "usermix",
  state: () => ({
    items: [],
    total: 0,
    item: {},
    details: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "Favorite perfumes", key: "fps", align: "center" },
      { title: "Name", key: "name", align: "center" },
      { title: "From label", key: "from", align: "center" },
      { title: "Date label", key: "date", align: "center" },
      { title: "Bottle", key: "bottle", align: "center" },
      { title: "Accords", key: "notes", align: "center" },
      { title: "Suggested Accords", key: "suggested_notes", align: "center" },
      {
        title: "Lead to sku-s (from #1 to #3 respectively)",
        key: "lead_to",
        align: "center",
      },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "name",
      searchItems: ["name"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as Error).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as Error).message);
      } finally {
        this.loading = false;
      }
    },
    async getDetails(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getDetails(id);
        this.details = result;
      } catch (error) {
        snackbarStore.error((error as Error).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
