import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import ShopusersApi from "@/api/shopusers";
const restApi = new ShopusersApi();
export const useShopuserStore = defineStore({
  id: "shopuser",
  state: () => ({
    items: [],
    total: 0,
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "Name", key: "name", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Country", key: "country", align: "center" },
      { title: "City", key: "city", align: "center" },
      { title: "Address", key: "address", align: "center" },
      { title: "Zip", key: "postalcode", align: "center" },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "name",
      searchItems: [
        "name",
        "email",
        "country",
        "city",
        "address",
        "postalcode",
        "id",
      ],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: object) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.insertItem(item);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        await restApi.updateItem(item.id, values);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
