<template>
      <v-text-field
        autocomplete="off"
        v-model="value"
        :placeholder="item.placeholder"
        :label="item.text"
        :name="item.text"
        clearable
        :error-messages="errorMessage"
        variant="outlined"
      @input="handleChange"
      @blur="handleBlur"
      >
      </v-text-field>
</template>
<script setup>
import { useField } from 'vee-validate';
const props = defineProps({
item: Object
})
const {
  value,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.item.name,props.item.rules);
</script>