import { createRouter, createWebHistory } from 'vue-router'

import { useUserStore } from '@/stores/user';
import guest from '@/middleware/guest'
import auth from '@/middleware/auth'
import middlewarePipeline from '@/middleware/middlewarePipeline'
import Accords from "@/router/accords";
import Bottles from "@/router/bottles";
import Subscribers from "@/router/subscribers";
import Discounts from "@/router/discounts";
import Users from "@/router/users";
import Shopusers from "@/router/shopusers";
import Rawdatas from "@/router/rawdatas";
import Products from "@/router/products";
import Usermixes from "@/router/usermixes";
import Orders from "@/router/orders";
import Engine from "@/router/engine";
import PageNotFound from "@/views/Error404Page.vue";
function loadAdminView(view) {
	return () => import(`@/views/admin/${view}.vue`);
}

function loadView(view) {
	return () => import(`@/views/${view}.vue`);
}


const routes = [
	{
		path: "/dashboard",
		redirect: "dashboard",
		meta: {
			middleware: [
				auth
			]
		},
		component: () => import("@/layout/admin/AdminWrapper.vue"),
		children: [
			{
				path: "",
				name: "dashboard",
				meta: {
				},
				component: loadAdminView("Dashboard")
			},
			...Accords,
			...Bottles,
			...Discounts,
			...Subscribers,
			...Users,
			...Shopusers,
			...Rawdatas,
			...Products,
			...Usermixes,
			...Orders

		]
	},
	{
		path: '/admin/',
		name: "login",
		component: loadAdminView("Login"),
		meta: {
			middleware: [
				guest
			]
		},
	},
	{
		path: '/design-your-fragrance',
		component: () => import("@/layout/EngineWrapper.vue"),
		children: [
			...Engine
		]
	},
	{
		path: '/',
		component: () => import("@/layout/HomeWrapper.vue"),
		children: [
			{
				path: "",
				name: "home",
				meta: {
				},
				component: () => import(`@/views/Home.vue`)
			},
			/*{
				path: "make-a-gift",
				name: "gift",
				meta: {
				},
				component: loadView("Gift")
			},*/
			{
				path: "how-it-works",
				name: "how-it-works",
				meta: {
				},
				component: () => import(`@/views/HowItWorks.vue`)
			},
			{
				path: "about-us",
				name: "about",
				meta: {
				},
				component: () => import(`@/views/About.vue`)
			},
			{
				path: "inspiration",
				name: "inspiration",
				meta: {
				},
				component: () => import(`@/views/Home.vue`)
			},
			{
				path: "data-privacy",
				name: "data-privacy",
				meta: {
				},
				component: () => import(`@/views/DataPrivacy.vue`)
			},
			{
				path: "contact-us",
				name: "contact-us",
				meta: {
				},
				component: () => import(`@/views/ContactUs.vue`)
			},
			{
				path: "shipping-and-returns",
				name: "shipping",
				meta: {
				},
				component: () => import(`@/views/ShippingAndReturns.vue`)
			},
			{
				path: "terms-and-conditions",
				name: "terms",
				meta: {
				},
				component: () => import(`@/views/TermsAndConditions.vue`)
			},

		]
	},
	{
		path: "/:catchAll(.*)",
		name: '404',
		component: PageNotFound,

	},
]

const router = createRouter({
	scrollBehavior: () => ({ left: 0, top: 0 }),
	history: createWebHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	if (!to.meta.middleware) {
		return next()
	}
	const middleware = to.meta.middleware

	const context = {
		to,
		from,
		next,
	}


	return middleware[0]({
		...context,
		next: middlewarePipeline(context, middleware, 1)
	})

})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
	if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
		if (!localStorage.getItem('vuetify:dynamic-reload')) {
			console.log('Reloading page to fix dynamic import error')
			localStorage.setItem('vuetify:dynamic-reload', 'true')
			location.assign(to.fullPath)
		} else {
			console.error('Dynamic import error, reloading page did not fix it', err)
		}
	} else {
		console.error(err)
	}
})

export default router;