<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useBottleStore } from '@/stores/bottle'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useBottleStore()
const formData = [
        {
          text: "Name*",
          value: "",
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Price($):",
          value: "",
          placeholder: "Price($):",
          name: "price",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Description",
          placeholder: "Description",
          fieldName: "StandardTextAreaField",
          value: "",
          name: "description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Bottle category*:",
          placeholder: "Bottle category*:",
          fieldName: "SelectField",
          value: null,
          name: "category",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          multiple: false,
          chips: true,
          items: ["Squared", "Rounded","Tall"],
          rules:  yup.string().required()
        },
        {
          text: "Is bottle tall?*:",
          placeholder: "Is bottle tall?*",
          fieldName: "SwitchField",
          value: 0,
          trueValue: 1,
          falseValue:0,
          name: "thin",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Carousel postion:",
          placeholder: "Carousel postion:",
          fieldName: "SelectField",
          value: 0,
          name: "carousel_position",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          items:[{text: "Left", value: 2},{text: "Right", value: 1},{text: "Center", value: 0}],
          rules:  ""
        },
        {
          text: "Size(ml):",
          value: "",
          placeholder: "Size(ml):",
          name: "size",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle size icon",
          value: [],
          placeholder: "Bottle size icon",
          name: "size_icon",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },   
        {
          text: "Width (mm):",
          value: "",
          placeholder: "Width (mm):",
          name: "width",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle width icon",
          value: [],
          placeholder: "Bottle width icon",
          name: "width_icon",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },   
        {
          text: "Height(mm):",
          value: "",
          placeholder: "Height(mm):",
          name: "height",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle height icon",
          value: [],
          placeholder: "Bottle height icon",
          name: "height_icon",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },   
        {
          text: "Weight(gr):",
          value: "",
          placeholder: "Weight(gr):",
          name: "weight",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },        
        {
          text: "Bottle weight icon",
          value: [],
          placeholder: "Bottle weight icon",
          name: "weight_icon",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Image bottle selector*",
          value: [],
          placeholder: "Image bottle selector",
          name: "image",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },        
        {
          text: "Image bottle overview ",
          value: [],
          placeholder: "Image bottle overview",
          name: "image2",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle label front ",
          value: [],
          placeholder: "Image label front",
          name: "image3",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle label back ",
          value: [],
          placeholder: "Image label back",
          name: "image4",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle checkout",
          value: [],
          placeholder: "Image bottle checkout",
          name: "image5",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },
        {
          text: "Image bottle box",
          value: [],
          placeholder: "Image bottle box",
          name: "image6",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },
      ];

</script>
 
