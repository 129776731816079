import RestApi from "@/api/RestApi";

export default class OrdersApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "orders/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getItem(id) {
    let url = this.baseEndpoint + id;
    let response = await this.getData(url);
    return response;
  }

  async insertItem(item) {
    let url = this.baseEndpoint;
    let response = await this.postData(url, item);
    return response;
  }

  async updateItem(id, item) {
    let url = this.baseEndpoint + id;
    let response = await this.putData(url, item);
    return response;
  }

  async deleteItem(id) {
    let url = this.baseEndpoint + id;
    let response = await this.deleteData(url);
    return response;
  }

  async initiatePayment(order) {
    let url = this.domain + "payment/initiate";
    let response = await this.postData(url, order);
    return response;
  }
  async initiateGiftPayment() {
    let url = this.domain + "payment/initiate-gift";
    let response = await this.postData(url);
    return response;
  }
  async claimGift(item) {
    let url = this.domain + "payment/claim-gift";
    let response = await this.postData(url, item);
    return response;
  }
  async completePayment(item) {
    let url = this.domain + "payment/complete";
    let response = await this.postData(url, item);
    return response;
  }

  async completeGiftPayment(item) {
    let url = this.domain + "payment/complete-gift";
    let response = await this.postData(url, item);
    return response;
  }

  async failedPayment(item) {
    let url = this.domain + "payment/failed";
    let response = await this.postData(url, item);
    return response;
  }
}
