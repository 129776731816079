<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useSubscriberStore } from '@/stores/subscriber'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useSubscriberStore()
const {getItem} = store
await getItem(router.params.id)
const { item } = store
const { email } = item;
const formData = [
        {
          text: "Email*",
          value: email,
          placeholder: "Email",
          name: "email",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-12 v-col-sm-12 v-col-md-12",
          rules:  yup.string().required()
        },
      ];

</script>