import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useOrderStore } from "@/stores/order";
import { useFileStore } from "@/stores/file";
import AccordsApi from "@/api/accords";
const restApi = new AccordsApi();
export const useAccordStore = defineStore({
  id: "accord",
  state: () => ({
    items: [],
    allItems: [],
    total: 0,
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "Name", key: "name", align: "center" },
      { title: "Image", key: "image", align: "center" },
      {
        title: "Description",
        key: "description",
        align: "center",
        width: "20%",
      },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "name",
      searchItems: ["name", "description", "id"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  getters: {
    fileLoading() {
      const file = useFileStore();
      return file.loading;
    },
  },
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getAll() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getAll();
        this.allItems = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getSumData() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      const order = useOrderStore();
      const perfumes = order.currentOrderDetails.perfumes
        .map((item: any) => item.id)
        .join(",");

      try {
        const { result } = await restApi.getSumData(perfumes);
        order.currentOrderDetails.accords = [...result.accords];
        order.currentOrderDetails.accordOrig = [...result.accords];
        order.currentOrderDetails.products = result.products;
        return result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: any) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const cloneItem = structuredClone(item);
        cloneItem.image = item.image[0].name;
        cloneItem.image_large = item.image_large[0].name;
        await restApi.insertItem(cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        const cloneItem = structuredClone(values);
        cloneItem.image = cloneItem.image[0].name;
        cloneItem.image_large = cloneItem.image_large[0].name;
        await restApi.updateItem(item.id, cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
