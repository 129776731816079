import View from "@/views/admin/Bottle.vue";
import Index from "@/components/admin/bottles/List.vue";
import Create from "@/components/admin/bottles/Create.vue";
import Edit from "@/components/admin/bottles/Edit.vue";
export default [
  {
    path: "/bottles",
    name: "bottles",
    component: View,
    title: "Bottles",
    redirect: {
      name: "list-bottle"
    },
    children: [
      {
        path: "list",
        name: "list-bottle",
        meta: {
          icon: "mdi-bottle-wine"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-bottle",
        meta: {
          storeAction: "bottle/insertItem",
          namespace: "bottle",
          store: "bottle",
          title: "Create bottle",
          icon: "mdi-bottle-wine"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-bottle",
        meta: {
          storeAction: "bottle/updateItem",
          namespace: "bottle",
          store: "bottle",
          title: "Edit bottle",
          icon: "mdi-bottle-wine"
        },
        component: Edit
      }
    ]
  }
];
