<template>
    <v-switch
        v-model="value"
        :placeholder="item.placeholder"
        :label="item.text"
        :true-value="item.trueValue || true"
        :false-value="item.falseValue || false"
        :name="item.text"
        :hint="item.hint"
        persistent-hint
        :error-messages="errorMessage"
    ></v-switch>
</template>
<script setup>
import { useField } from 'vee-validate';
const props = defineProps({
item: Object
})
const {
  value,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.item.name,props.item.rules);
</script>