import { useUserStore } from '@/stores/user';
export default function auth ({ next }){
    const userStore = useUserStore()
    if(!userStore.token){
        return next({
           name: 'login'
        })
    }
   
    return next()
   }