import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
type MessageSchema = typeof en;

const i18n = createI18n<[MessageSchema], "en">({
  legacy: false,
  globalInjection: true,
  warnHtmlMessage: false,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "en",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: { en: en },
});

export default i18n;
