import View from "@/views/Engine.vue";
export default [
  {
    path: "",
    name: "engine",
    component: View,
    redirect: {
      name: "engine-bottle"
    },
    title: "Design your Fragrance",
    children: [
      {
        path: "bottle",
        name: "engine-bottle",
        component: () => import("@/components/engine/Bottle.vue")
      },
      {
        path: "label",
        name: "engine-label",
        component: () => import("@/components/engine/LabelDetails.vue")
      },
      {
        path: "gender",
        name: "engine-gender",
        component: () => import("@/components/engine/ChooseGender.vue")
      },
      {
        path: "analyze-or-gift",
        name: "engine-analyze-or-gift",
        component: () => import("@/components/engine/AnalyzeOrGift.vue")
      },
      {
        path: "analyze-perfumes",
        name: "engine-analyze",
        component: () => import("@/components/engine/AnalyzePerfumes.vue")
      },
      {
        path: "accords-confirm",
        name: "engine-accords-confirm",
        component: () => import("@/components/engine/AccordsConfirm.vue")
      },
      {
        path: "choose-profile",
        name: "engine-profile",
        component: () => import("@/components/engine/ChooseProfile.vue")
      },
      {
        path: "summary",
        name: "engine-summary",
        component: () => import("@/components/engine/Summary.vue")
      },
      {
        path: "checkout-gift",
        name: "engine-checkout-free",
        component: () => import("@/components/engine/CheckoutFree.vue")
      },
      {
        path: "checkout",
        name: "engine-checkout",
        component: () => import("@/components/engine/Checkout.vue")
      },
      {
        path: "thank-you",
        name: "thank-you",
        component: () => import("@/components/engine/ThankYou.vue")
      },
    ]
  }
];
