<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useDiscountStore } from '@/stores/discount'

const namespace = 'discount'
const icon = 'mdi-percent'
const title = 'Discounts'
const store = useDiscountStore()
</script>
