import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useFileStore } from "@/stores/file";
import BottleApi from "@/api/bottles";
const restApi = new BottleApi();
export const useBottleStore = defineStore({
  id: "bottle",
  state: () => ({
    items: [],
    allItems: [],
    total: 0,
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "Name", key: "name", align: "center" },
      { title: "Image", key: "image", align: "center" },
      { title: "Size (ml)", key: "size", align: "center" },
      { title: "Price ($)", key: "price", align: "center" },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "name",
      searchItems: ["name", "size", "price", "id"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  getters: {
    fileLoading() {
      const file = useFileStore();
      return file.loading;
    },
  },
  actions: {
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getAll() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getAll();
        this.allItems = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: any) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const cloneItem = structuredClone(item);
        cloneItem.image = item.image.length > 0 ? item.image[0].name : "";
        cloneItem.image2 = item.image2.length > 0 ? item.image2[0].name : "";
        cloneItem.image3 = item.image3.length > 0 ? item.image3[0].name : "";
        cloneItem.image4 = item.image4.length > 0 ? item.image4[0].name : "";
        cloneItem.image5 = item.image5.length > 0 ? item.image5[0].name : "";
        cloneItem.image6 = item.image6.length > 0 ? item.image6[0].name : "";
        cloneItem.size_icon =
          item.size_icon.length > 0 ? item.size_icon[0].name : "";
        cloneItem.width_icon =
          item.width_icon.length > 0 ? item.width_icon[0].name : "";
        cloneItem.weight_icon =
          item.weight_icon.length > 0 ? item.weight_icon[0].name : "";
        cloneItem.height_icon =
          item.height_icon.length > 0 ? item.height_icon[0].name : "";
        await restApi.insertItem(cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        const cloneItem = structuredClone(values);
        cloneItem.image =
          cloneItem.image.length > 0 ? cloneItem.image[0].name : "";
        cloneItem.image2 =
          cloneItem.image2.length > 0 ? cloneItem.image2[0].name : "";
        cloneItem.image3 =
          cloneItem.image3.length > 0 ? cloneItem.image3[0].name : "";
        cloneItem.image4 =
          cloneItem.image4.length > 0 ? cloneItem.image4[0].name : "";
        cloneItem.image5 =
          cloneItem.image5.length > 0 ? cloneItem.image5[0].name : "";
        cloneItem.image6 =
          cloneItem.image6.length > 0 ? cloneItem.image6[0].name : "";
        cloneItem.size_icon =
          cloneItem.size_icon.length > 0 ? cloneItem.size_icon[0].name : "";
        cloneItem.width_icon =
          cloneItem.width_icon.length > 0 ? cloneItem.width_icon[0].name : "";
        cloneItem.weight_icon =
          cloneItem.weight_icon.length > 0 ? cloneItem.weight_icon[0].name : "";
        cloneItem.height_icon =
          cloneItem.height_icon.length > 0 ? cloneItem.height_icon[0].name : "";
        await restApi.updateItem(item.id, cloneItem);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
