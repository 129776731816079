<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useRawdataStore } from '@/stores/rawdata'

const namespace = 'rawdata'
const icon = 'mdi-database'
const title = 'Raw data'
const store = useRawdataStore()
</script>
