import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import UsersApi from "@/api/users";
import router from "@/router";
const restApi = new UsersApi();
export const useUserStore = defineStore({
  id: "auth",
  state: () => ({
    user: JSON.parse(localStorage.getItem("user") || "null"),
    token: localStorage.getItem("token"),
    error: false,
    items: [],
    total: 0,
    item: {},
    loading: false,
    headers: [
      { title: "#ID", key: "id", align: "start", divider: true },
      { title: "First name", key: "first_name", align: "center" },
      { title: "Last name", key: "last_name", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Actions", key: "actions", align: "center", sortable: false },
    ],
    pagination: {
      page: 1,
      orderByArray: [{ key: "id", order: "desc" }],
      searchBy: "first_name",
      searchItems: ["first_name", "last_name", "email", "id"],
      orderBy: "id",
      mustSort: true,
      descending: "desc",
      search: "",
      limit: 12,
    },
  }),
  actions: {
    async login(email: string, password: string) {
      const snackbarStore = useSnackbarStore();
      try {
        const response = await restApi.login({ email, password });
        const { user, token } = response;
        this.user = user;
        this.token = token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        snackbarStore.success("Logged in successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      }
    },
    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      router.push({ name: "login" });
    },
    async getItems() {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        this.pagination.orderBy = this.pagination.orderByArray[0].key;
        this.pagination.descending = this.pagination.orderByArray[0].order;
        const { result } = await restApi.getList(this.pagination);
        this.total = result.total;
        this.items = result.data;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
    async getItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result } = await restApi.getItem(id);
        this.item = result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async insertItem(item: object) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.insertItem(item);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item: { id: number; values: any }) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const values = item.values;
        await restApi.updateItem(item.id, values);
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(id: number) {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        await restApi.deleteItem(id);
        snackbarStore.success("Deleted successfully");
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
