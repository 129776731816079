<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
    :action-routes="false"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useUsermixStore } from '@/stores/usermix'

const namespace = 'usermix'
const icon = 'mdi-heart'
const title = 'Usermixes'
const store = useUsermixStore()
</script>
