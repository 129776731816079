import View from "@/views/admin/Rawdata.vue";
import Index from "@/components/admin/rawdatas/List.vue";
import Create from "@/components/admin/rawdatas/Create.vue";
import Edit from "@/components/admin/rawdatas/Edit.vue";
export default [
  {
    path: "/rawdatas",
    name: "rawdatas",
    component: View,
    title: "Raw datas",
    redirect: {
      name: "list-rawdata"
    },
    children: [
      {
        path: "list",
        name: "list-rawdata",
        meta: {
          icon: "mdi-database"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-rawdata",
        meta: {
          storeAction: "rawdata/insertItem",
          namespace: "rawdata",
          store: "rawdata",
          title: "Create rawdata",
          icon: "mdi-database"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-rawdata",
        meta: {
          storeAction: "rawdata/updateItem",
          namespace: "rawdata",
          store: "rawdata",
          title: "Edit rawdata",
          icon: "mdi-database"
        },
        component: Edit
      }
    ]
  }
];
