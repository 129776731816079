import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import FilesApi from "@/api/files";

interface FileItem {
  file: File;
  file_path: string;
}

interface UploadResult {
  result: any; // Define the type of result based on the expected structure
}
const restApi = new FilesApi();
export const useFileStore = defineStore({
  id: "file",
  state: () => ({
    loading: false,
  }),
  actions: {
    async uploadFilePublic(item: FileItem): Promise<UploadResult | void> {
      this.loading = true;
      const snackbarStore = useSnackbarStore();
      try {
        const { result }: UploadResult = await restApi.uploadFilePublic(item);
        return result;
      } catch (error) {
        snackbarStore.error((error as any).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
