<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useUserStore } from '@/stores/user'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useUserStore()
const {getItem} = store
await getItem(router.params.id)
const { item } = store
const { first_name, last_name, email } = item;
const formData = [
        {
          text: "First name*",
          value: first_name,
          placeholder: "First name*",
          name: "first_name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Last name*",
          value: last_name,
          placeholder: "Last name*",
          name: "last_name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Email*",
          value: email,
          placeholder: "Email*",
          name: "email",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required().email()
        },
        {
          text: "Password",
          value: '',
          placeholder: "Password",
          name: "password",
          hint: "Leave blank if you don't want to change the password",
          fieldName: "PasswordField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ''
        },
      ];

</script>