<template>
    <v-dialog  v-model="localDialog" persistent  width="auto">
      <v-card>
        <v-card-title class="error pl-3 white--text">Usermix details</v-card-title>
        <v-divider></v-divider>
        <v-list>
            <v-list-item>
                    <v-list-item-title>Selected perfumes:</v-list-item-title>
                    <v-list-item-subtitle>{{details.fps}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Selected accords: </v-list-item-title>
                    <v-list-item-subtitle>{{details.notes}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Accords suggested by engine : </v-list-item-title>
                    <v-list-item-subtitle>{{details.suggested_notes}}</v-list-item-subtitle>
                </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Lead to SKU'S (from #1 to #3 respectively)</v-list-item-title>
                    <v-list-item-subtitle>{{details.lead_to}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Bottle</v-list-item-title>
                    <v-list-item-subtitle>{{details.bottle}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>Mix name</v-list-item-title>
                    <v-list-item-subtitle>{{details.name}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.from">
                    <v-list-item-title>Label from</v-list-item-title>
                    <v-list-item-subtitle>{{details.from}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="details.date">
                    <v-list-item-title>Label date</v-list-item-title>
                    <v-list-item-subtitle>{{details.date}}</v-list-item-subtitle>
                    </v-list-item>

        </v-list>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click.native="$emit('close-usermix-dialog')">
          <v-icon size="20" left>mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script setup>
  import { computed } from "vue";
  import { storeToRefs } from "pinia";
  import { useUsermixStore } from '@/stores/usermix'
  const store = useUsermixStore()
  const {getDetails} = store
  const { details } = storeToRefs(store)
    const props = defineProps({
    dialog: Boolean,
    usermixId: [String, Number],
    })
await getDetails(props.usermixId)
  const localDialog = computed(() => { return props.dialog })
  
    </script>