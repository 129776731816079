import RestApi from "@/api/RestApi";

export default class UsermixesApi extends RestApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "usermixes/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async getItem(id) {
    let url = this.baseEndpoint + id;
    let response = await this.getData(url);
    return response;
  }
  async getDetails(id) {
    let url = this.baseEndpoint + 'getDetails/' + id;
    let response = await this.getData(url);
    return response;
  }

}
