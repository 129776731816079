<template>
  <simple-table
    :store="store"
    :icon="icon"
    :title="title"
    :namespace="namespace"
  ></simple-table>
</template>
  
<script setup>
import SimpleTable from "@/components/core/tables/SimpleTable.vue";
import { useUserStore } from '@/stores/user'

const namespace = 'user'
const icon = 'mdi-account-star'
const title = 'Admin users'
const store = useUserStore()
</script>
