<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useDiscountStore } from '@/stores/discount'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useDiscountStore()
const formData = [
        {
          text: "Discount code*",
          value: "",
          placeholder: "Discount code*",
          name: "discount_code",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Name",
          value: "",
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Date From*",
          value: null,
          placeholder: "Date From*",
          name: "date_from",
          fieldName: "DateField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.date().required()
        },
        {
          text: "Date To*",
          value: null,
          placeholder: "Date To*",
          name: "date_to",
          fieldName: "DateField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Percentage (%)*",
          value: "",
          placeholder: "Percentage (%)*",
          name: "percentage",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "How many times this code can be used?*",
          value: "",
          placeholder: "How many times this code can be used?*",
          name: "one_time",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Is it free?:",
          placeholder: "Is it free?",
          fieldName: "SwitchField",
          value: 0,
          trueValue: 1,
          falseValue:0,
          name: "free",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          hint:"If it's free, select Free from select box and set the discount percentage to 100",
          rules:  yup.string().required()
        },
      ];

</script>
 
