import View from "@/views/admin/Discount.vue";
import Index from "@/components/admin/discounts/List.vue";
import Create from "@/components/admin/discounts/Create.vue";
import Edit from "@/components/admin/discounts/Edit.vue";
export default [
  {
    path: "/discounts",
    name: "discounts",
    component: View,
    title: "Discounts",
    redirect: {
      name: "list-discount"
    },
    children: [
      {
        path: "list",
        name: "list-discount",
        meta: {
          icon: "mdi-percent"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-discount",
        meta: {
          storeAction: "discount/insertItem",
          namespace: "discount",
          store: "discount",
          title: "Create discount",
          icon: "mdi-percent"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-discount",
        meta: {
          storeAction: "discount/updateItem",
          namespace: "discount",
          store: "discount",
          title: "Edit discount",
          icon: "mdi-percent"
        },
        component: Edit
      }
    ]
  }
];
