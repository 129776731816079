<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useBottleStore } from '@/stores/bottle'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useBottleStore()
const {getItem} = store
await getItem(router.params.id)
const { item } = store
const { name, description, image, image2,image3,image4,image5,image6,width_icon,weight_icon,size_icon,height_icon, category, thin, carousel_position, size, price, width, height, weight } = item;
const imageName = image!==''? [new File([], image.split(/(\\|\/)/g).pop())] : []
const imageName2 = image2 && image2!=='' ? [new File([], image2.split(/(\\|\/)/g).pop())] : []
const imageName3 = image3 && image3!==''? [new File([], image3.split(/(\\|\/)/g).pop())] : []
const imageName4 = image4 && image4!==''? [new File([], image4.split(/(\\|\/)/g).pop())] : []
const imageName5 = image5 && image5!==''? [new File([], image5.split(/(\\|\/)/g).pop())] : []
const imageName6 = image6 && image6!==''? [new File([], image6.split(/(\\|\/)/g).pop())] : []
const widthIconName = width_icon && width_icon!=='' ? [new File([], width_icon.split(/(\\|\/)/g).pop())] : []
const weightIconName = weight_icon && weight_icon!==''? [new File([], weight_icon.split(/(\\|\/)/g).pop())] : []
const sizeIconName = size_icon && size_icon!==''? [new File([], size_icon.split(/(\\|\/)/g).pop())] : []
const heightIconName = height_icon && height_icon!==''? [new File([], height_icon.split(/(\\|\/)/g).pop())] : []
const formData = [
        {
          text: "Name*",
          value: name,
          placeholder: "Name",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Price($):",
          value: price,
          placeholder: "Price($):",
          name: "price",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Description",
          placeholder: "Description",
          fieldName: "StandardTextAreaField",
          value: description,
          name: "description",
          class: "v-col-sm-12 v-col-md-12",
          rules: ""
        },
        {
          text: "Bottle category*:",
          placeholder: "Bottle category*:",
          fieldName: "SelectField",
          value: category,
          name: "category",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          multiple: false,
          chips: true,
          items: ["Squared", "Rounded","Tall"],
          rules:  yup.string().required()
        },
        {
          text: "Is bottle tall?*:",
          placeholder: "Is bottle tall?*",
          fieldName: "SwitchField",
          value: thin,
          trueValue: 1,
          falseValue:0,
          name: "thin",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Carousel postion:",
          placeholder: "Carousel postion:",
          fieldName: "SelectField",
          value: carousel_position?parseInt(carousel_position):null,
          name: "carousel_position",
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          items:[{text: "Left", value: 2},{text: "Right", value: 1},{text: "Center", value: 0}],
          rules:  ""
        },
        {
          text: "Size(ml):",
          value: size,
          placeholder: "Size(ml):",
          name: "size",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle size icon",
          placeholder: "Bottle size icon",
          name: "size_icon",
          filePath: 'bottles/',
          value: sizeIconName,
          imagePath: size_icon,
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Width (mm):",
          value: width,
          placeholder: "Width (mm):",
          name: "width",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle width icon",
          placeholder: "Bottle width icon",
          name: "width_icon",
          filePath: 'bottles/',
          value: widthIconName,
          imagePath: width_icon,
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Height(mm):",
          value: height,
          placeholder: "Height(mm):",
          name: "height",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle height icon",
          placeholder: "Bottle height icon",
          name: "height_icon",
          filePath: 'bottles/',
          value: heightIconName,
          imagePath: height_icon,
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Weight(gr):",
          value: weight,
          placeholder: "Weight(gr):",
          name: "weight",
          fieldName: "StandardTextField",
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Bottle weight icon",
          placeholder: "Bottle weight icon",
          name: "weight_icon",
          filePath: 'bottles/',
          value: weightIconName,
          imagePath: weight_icon,
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  ""
        },
        {
          text: "Image bottle selector*",
          value: imageName,
          imagePath: image,
          placeholder: "Image bottle selector",
          name: "image",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  yup.array().required()
        },        
        {
          text: "Image bottle overview",
          value: imageName2,
          imagePath: image2,
          placeholder: "Image bottle overview",
          name: "image2",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle label front",
          value: imageName3,
          imagePath: image3,
          placeholder: "Image label front",
          name: "image3",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle label back",
          value: imageName4,
          imagePath: image4,
          placeholder: "Image label back",
          name: "image4",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle checkout",
          value: imageName5,
          imagePath: image5,
          placeholder: "Image bottle checkout",
          name: "image5",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },        
        {
          text: "Image bottle box",
          value: imageName6,
          imagePath: image6,
          placeholder: "Image bottle box",
          name: "image6",
          filePath: 'bottles/',
          fileNames: [],
          fieldName: "FileUploadSimpleField",
          multiple:true,
          required: true,
          class: "v-col-sm-12 v-col-md-12",
          rules:  ""
        },
      ];

</script>