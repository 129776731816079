<template>
    <v-row v-for="(option,index) in value" :key="index">
      <v-col cols="6">
        <v-autocomplete
      class="no-drag pa-0"
      autocomplete="off"
      :items="allItems"
      :item-value="item.itemValue || 'id'"
      :item-title="item.itemText || 'name'"
      chips
      :name="item.name+index"
      v-model="value[index]"
      :placeholder="item.placeholder"
      :label="item.text"
      clearable
      :error-messages="errorMessage"
      variant="outlined"
    >
    </v-autocomplete>
      </v-col>
      <v-col cols="3"  v-if="index != 0">
      <v-btn
        class="ml-2"
        prepend-icon="mdi-delete"
        color="primary"
        @click="removeValue(index)"
        >Remove</v-btn>
      </v-col>
      <v-col cols="3" v-if="index == value.length - 1">
      <v-btn
        class="ml-2"
        prepend-icon="mdi-plus"
        color="primary"
        @click="addValue"
        >Add</v-btn>
      </v-col>
    
    </v-row>
</template>
<script setup>
import { useField } from 'vee-validate';
  import { storeToRefs } from 'pinia'
const props = defineProps({
item: Object
})
const { allItems} = storeToRefs(props.item.store)
  const { getAll } = props.item.store
const {
value,
errorMessage,
} = useField(props.item.name,props.item.rules);
getAll()
const addValue = () => {
  value.value.push(null)
}
const removeValue = (index) => {
  value.value.splice(index,1)
}
</script>

