import View from "@/views/admin/Subscriber.vue";
import Index from "@/components/admin/subscribers/List.vue";
import Create from "@/components/admin/subscribers/Create.vue";
import Edit from "@/components/admin/subscribers/Edit.vue";
export default [
  {
    path: "/subscribers",
    name: "subscribers",
    component: View,
    title: "Subscribers",
    redirect: {
      name: "list-subscriber"
    },
    children: [
      {
        path: "list",
        name: "list-subscriber",
        meta: {
          icon: "mdi-email"
        },
        component: Index
      },
      {
        path: "create",
        name: "create-subscriber",
        meta: {
          storeAction: "subscriber/insertItem",
          namespace: "subscriber",
          store: "subscriber",
          title: "Create subscriber",
          icon: "mdi-email"
        },
        component: Create
      },
      {
        path: "edit/:id",
        name: "edit-subscriber",
        meta: {
          storeAction: "subscriber/updateItem",
          namespace: "subscriber",
          store: "subscriber",
          title: "Edit subscriber",
          icon: "mdi-email"
        },
        component: Edit
      }
    ]
  }
];
