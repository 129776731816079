<template>
  <form-wizard
    :form-data="formData"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
  ></form-wizard>
</template>  
<script setup>
import FormWizard from "@/components/core/form/FormWizard.vue";
import { useShopuserStore } from '@/stores/shopuser'
import { useRoute } from 'vue-router'
import * as yup from 'yup';
const router = useRoute();
const icon = router.meta.icon || "";
const title = router.meta.title || "";
const namespace = router.meta.namespace || "";
const store = useShopuserStore()
const formData = [
        {
          text: "Name*",
          value: "",
          placeholder: "Name*",
          name: "name",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required()
        },
        {
          text: "Email*",
          value: "",
          placeholder: "Email*",
          name: "email",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-6 v-col-sm-12 v-col-md-6",
          rules:  yup.string().required().email()
        },
        {
          text: "Contact",
          value: "",
          placeholder: "Contact",
          name: "contact",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules: ""
        },
        {
          text: "Country*",
          value: "",
          placeholder: "Country*",
          name: "country",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "State",
          value: "",
          placeholder: "State",
          name: "state",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules: ""
        },
        {
          text: "City*",
          value: "",
          placeholder: "City*",
          name: "city",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Address*",
          value: "",
          placeholder: "Address*",
          name: "address",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
        {
          text: "Postal code*",
          value: "",
          placeholder: "Postal code*",
          name: "postalcode",
          fieldName: "StandardTextField",
          required: true,
          class: "v-col-4 v-col-sm-12 v-col-md-4",
          rules:  yup.string().required()
        },
      ];

</script>
 
